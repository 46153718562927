/* COLORS */
$core-blue: rgb(233, 64, 87);

/* COLOR ASSIGNMENTS */
$primary: $core-blue;

/* MISC ASSIGNMENT */
$br: 4px;
$font--large: 26px;

.proceed-to-pay-btn {
  background-color: #f12525 !important;
  color: #ffffff !important;
  font-family: "Montserrat", Sans-serif !important;
}

/* GLOBAL */
* {
  box-sizing: border-box;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

h1,
h2,
h3,
h4 {
  font-weight: normal;
}

/* PEN SPECIFIC */

main {
  display: flex;
  > div {
    justify-content: center;
    flex-basis: 100%;
  }
}

.form-row {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  background-color: #fff;
  display: block;
  padding: 32px 24px;
  border-bottom: 1px solid #eee;
  // cursor: pointer;
  position: relative;
  font-size: $font--large;
  color: $primary;
  &:first-child {
    // border-radius: $br $br 0 0;
  }
  &:last-child {
    border-bottom: 0 none;
    // border-radius: 0 0 $br $br;
  }
}

.input-text {
  margin-bottom: 5px;
}

.link {
  color: $primary;
}

.subLabel {
  font-size: 1rem;
  color: #333;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
}

.row {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  &--single {
    height: 34px;
  }
}

.data-cluster {
  position: relative;
  padding-left: 48px;
  width: 100%;
}

.data-cluster-checked {
  position: relative;
  padding-left: 48px;
  width: 100%;
}

.input-wrapper:before {
  content: "$";
}
.custom-field {
  -webkit-appearance: textfield;
  background-color: transparent;
  outline: 0 none;
  border: 0 none;
  padding: 0;
  margin: 0;
  font-size: $font--large;
  overflow: visible;
  color: $primary;
  font-family: "Montserrat", Sans-serif;
}

::placeholder {
  color: #ccc;
}

.pen {
  justify-content: center;
  padding: 20px 30px;
  background-color: #fff;
  width: 600px;
}

.payment-option-logo {
  height: 30px;
}

.custom-radio-btn {
  // height: 100%;
  // width: 100%;
  // opacity: 0;
}

label.radio {
  cursor: pointer;
  width: 100%;
  height: 100%;
}
label [type="radio"] {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  cursor: pointer;
  opacity: 0;
}

form {
}

.description {
  color: #fff;
  margin-bottom: 60px;
  h1 {
    font-size: 1.2rem;
    margin-top: 0;
    margin-bottom: 12px;
  }
  p {
    font-size: 1.8rem;
    margin-top: 0;
    font-weight: 300;
  }
}

form label {
  background-color: #fff;
  &:first-child {
    // border-radius: 4px 4px 0 0;
  }
  &:last-child {
    // border-radius: 0 0 4px 4px;
    box-shadow: 0 16px 8px -8px rgba(0, 0, 0, 0.1);
    margin-bottom: 40px;
  }
}

/* RADIO OVERRIDE */
label [type="radio"] {
  //display:none;
  position: absolute;
  // left: -9999px;
}

.data-cluster:before {
  content: "";
  border: 2px solid $primary;
  width: 30px;
  height: 30px;
  display: flex;
  position: absolute;
  left: 0;
  border-radius: 50%;
}

.data-cluster-checked:before {
  content: "";
  border: 2px solid $primary;
  width: 30px;
  height: 30px;
  display: flex;
  position: absolute;
  left: 0;
  border-radius: 50%;
}
.data-cluster:after {
  transform: scale(0);
  content: "";
}

.data-cluster-checked:after {
  transform: scale(0);
  content: "";
}
.data-cluster-checked:after {
  content: "";
  transform: scale(1);
  transition: all 0.2s ease-in-out;
  width: 18px;
  height: 18px;
  background-color: $primary;
  border-radius: 50%;
  position: absolute;
  left: 8px;
  top: 8px;
}

.selected {
  background-color: #f3f9fc;
  transition: background-color 0.2s ease-in-out;
}

button {
  width: 100%;
  line-height: 3em;
  // border-radius: 4px;
  border: 0 none;
  font-size: 1.3rem;
  background-color: lighten($primary, 5%);
  color: #fff;
  transition: background-color 0.2s ease-in-out;
  cursor: pointer;
  border: 2px solid transparent;
  font-family: "Montserrat", Sans-serif;

  &:hover {
    background-color: $primary;
  }
  &.empty {
    border: 2px solid lighten($primary, 5%);
    background-color: transparent;
    cursor: default;
    font-weight: 300;
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 1000px #f3f9fc inset;
  -webkit-text-fill-color: $primary;
}

.proceed-to-pay-btn:disabled {
  opacity: 0.3;
}
