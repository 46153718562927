@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,600;1,500&display=swap");

input::placeholder {
  color: rgba(0, 0, 0, 0.5) !important;
}

.PhoneInputInput {
  border: 1px solid rgb(0 -16 0 / 0.23);
  border-radius: 4px;
  color: rgba(0, 0, 0, 0.87) !important;
  cursor: text;
  display: inline-flex;
  position: relative;
  font-size: 1rem;
  box-sizing: border-box;
  align-items: center;
  font-family: "Montserrat", Sans-serif;
  padding: 18.5px 14px;
}

.phone-number-invalid {
  color: #f44336;
  border-color: #f44336;
}

.MuiAppBar-colorPrimary {
  color: black !important;
  background-color: white !important;
}
